/*
	
	Stylesheet for Spoken Gospel
	
*/

@import 'fonts.css';
@import 'foundation.min.css';

/*
	
						General
	
*/

@media (max-width: 1024px) {
	
	html {
		font-size: 90%;
	}
	
}
@media (max-width: 640px) {
	
	html {
		font-size: 80%;
	}
	
}

// -------- Variables -------- //

// Colors
$navy: #1B364C;
$dark-navy: #182f43;
$dark-navy-text: #0f1e2b;
$gold: #efcd63;
$light-grey: #f2f2f2;

// -------- Foundation -------- //
.grid-container {
	max-width: 90rem;
	padding: 0px 10vw;
	margin: 0 auto;
}

// -------- Utility -------- //

// Lines
.line-full {
	width: 100%;
	height: 2px;
	background: $gold;
}
.line-two-third {
	width: 75%;
	height: 2px;
	background: $gold;
}
.line-one-third {
	width: 50%;
	height: 2px;
	background: $gold;
}

// Background Images
.bg-image {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

// Columns
.two-columns {
	display: block;
	column-count: 2;
	column-gap: 40px;
	column-fill: balance;
}
@media (max-width: 640px) {
	.two-columns {
		column-count: 1;
	}
}

// -------- Typography -------- //

h1 {
	font: normal 400 2.5rem/1.3em "proxima", sans-serif;
	letter-spacing: .2em;
	text-transform: uppercase;
	
	color: $navy;
}

h2 {
	font: normal 400 2.25rem/1.3em "proxima", sans-serif;
	letter-spacing: .2em;
	text-transform: uppercase;
	
	color: $navy;
}

h3 {
	font: normal 700 1.5rem/1.3em "proxima", sans-serif;
	letter-spacing: .2em;
	text-transform: uppercase;
	
	color: $navy;
}

h5 {
	font: normal 700 1rem/1.2em "proxima", sans-serif;
	letter-spacing: .2em;
	text-transform: uppercase;
	
	color: $navy;
}

p {
	font: normal 400 1rem/1.2em "proxima", sans-serif;
	letter-spacing: .16em;
	
	color: $navy;
	
	&.larger {
		font-size: 1.2rem;
		line-height: 1.4em;
	}
}

h1, h2, h3, h4, h5, h6, p, a {
	margin-bottom: 0px;
	font-variation-ligatures: none;
	
	// colors
	&.white {
		color: #fff;
	}
	&.gold {
		color: $gold;
	}
	&.navy {
		color: $navy;
	}
	&.dark-navy {
		color: $dark-navy-text;
	}
	&.grey {
		color: #666;
	}
	&.light-grey {
		color: #B3B3B3;
	}
	&.success {
		color: #7ED841;
	}
	
	// Weights
	&.reg {
		font-weight: 400;
	}
	&.semi {
		font-weight: 500;
	}
	&.bold {
		font-weight: 700;
	}
	
	// Styles
	&.lowercase {
		text-transform: none;
		letter-spacing: .16em;
	}
	&.header-one {
		@extend h1;
	}
	&.underline {
		position: relative;
		margin-bottom: 15px;
		padding-bottom: 15px;
		
		&:after {
			display: block;
			content: "";
			position: absolute;
				bottom: 0;
				left: 0;
			width: 100%;
			height: 1px;
			background: $gold;
		}
	}
}

// -------- Interactive -------- //

// A tag
a {
	font: normal 400 1rem/1.2em "proxima", sans-serif;
	letter-spacing: .16em;
	text-transform: uppercase;
	
	color: $navy;
	
	transition: all .2s ease-out;
	
	&:hover {
		
		opacity: .5;
		
	}
	&.white {
		color: #fff;
	}
}

// Button
.button,
input[type="submit"] {
	display: inline-block;
	background: $navy;
	font-weight: 500;
	color: #fff;
	border-radius: 0px;
	padding: 10px 30px;
	text-transform: uppercase;
	
	&:hover {
		opacity: 1;
		color: #fff;
		&.gold-texture {
			color: #fff;
			span {
				color: #fff;
				text-fill-color: #fff;
				-webkit-text-fill-color: #fff;
			}
			&:after {
				transform: translate(-50%, -50%) rotate(45deg);
			}
		}
	}
	&:active,
	&:focus {
		color: #fff;
		
		&.gold-texture {
			color: #fff;
			span {
				color: #fff;
				text-fill-color: #fff;
				-webkit-text-fill-color: #fff;
			}
			&:after {
				transform: translate(-50%, -50%) rotate(45deg);
			}
		}
	}
	
	&.gold-texture {
		position: relative;
		display: inline-block;
		font-weight: 700;
		line-height: 1.2em;
		-webkit-text-fill-color: transparent;
		text-fill-color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		background-position: left top;
		border: 3px solid;
		overflow: hidden;
		padding: 7px 30px;
		transition: all .3s ease-out;
		color: $gold;
		
		&:after {
			z-index: 1;
			display: block;
			content: "";
			position: absolute;
				top: 50%;
				left: 50%;
			width: 200%;
			height: 400%;
			background-image: url('../images/foil.jpg');
			transform: translate(-150%, -50%) rotate(45deg);
			transition: all .3s ease-out;
		}
		span {
			position: relative;
			z-index: 2;
			-webkit-text-fill-color: transparent;
			text-fill-color: transparent;
			-webkit-background-clip: text;
			background-clip: text;
		}
	}
	&.outline {
		background: transparent;
		border: 1.5px solid $navy;
		color: $navy;
		
		&:hover {
			color: #fff;
			background: $navy;
		}
	}
}

@media (max-width: 640px) {
	
	.button {
		&.gold-texture {
			color: $gold;
			-webkit-text-fill-color: inherit;
			text-fill-color: inherit;
			-webkit-background-clip: content;
			background-clip: content;
		}
	}
	
}
.gold-texture.ios {
	-webkit-text-fill-color: $gold !important;
	text-fill-color: $gold !important;
}

// Text Input
input[type="text"],
select {
	height: auto;
	border-radius: 0px;
	padding: 10px 20px;
	line-height: 1em;
	box-shadow: none;
	border: none;
	outline: none;
	@extend p;
	
	transition: all .2s ease-out;
	
	&:hover {
		background-color: #f4f4f4;
		box-shadow: none;
		border: none;
	}
	&:focus {
		box-shadow: none;
		outline: none;
		border: none;
	}
	&::placeholder {
		@extend p;
		line-height: 1.2em;
	}
}

// Submit
input[type="submit"] {
	cursor: pointer;
	color: #fff;
	background-color: $navy;
	transition: all .2s ease-out;
	@extend .button;
	@extend p;
	
	&:hover {
		background-color: #112230;
	}
}

// -------- Page Elements -------- //

// Header
header,
#fixed-header {
	position: relative;
	z-index: 400;
	background: $navy;
	& > .grid-x {
		padding: 20px 5vw;
		background: $navy;
	}
	.logo {
		width: 50px;
		margin-right: 20px;
	}
	.logo-link {
		margin-left: 0px;
	}
	.logo-container {
		&:hover {
			opacity: 1;
		}
	}
	h5 {
		display: inline-block;
	}
	a {
		margin-left: 40px;
	}
	.book-hovered-menu {
		padding: 20px 0px;
		position: absolute;
		z-index: -1;
			top: 100%;
			left: 0;
		width: 100%;
		background: $dark-navy;
		transform: translateY(-100%);
		transition: all .2s ease-out;
		.grid-container {
			max-width: 70rem;
		}
		.donate {
			margin-top: 25px;
		}
		p {
			color: #fff;
		}
	}
	.book-menu {
		cursor: pointer;
		a {
			cursor: pointer;
		}
		&.is-open {
			.book-hovered-menu {
				transform: translate(0px);
			}
		}
	}
	
	&.splash {
		.grid-container {
			& > .grid-x {
				padding: 20px 0px;
				background: $navy;
			}
		}
		.logo {
			width: 200px;
		}
	}
}
#fixed-header {
	position: fixed;
	z-index: 300;
	width: 100%;
	top: 0;
	left: 0;
	
	transform: translateY(-100%);
	transition: all .1s ease-out;
	
	&.is-visible {
		transform: translateY(0%);
	}
}
.hamburger {
	cursor: pointer;
	height: 42px;
	width: 48px;
	display: inline-block;
	padding: 20px 10px;
	margin-top: 4px;
	margin-right: 20px;
	position: relative;
	
	.hamburger-container {
		height: 100%;
		width: 100%;
		background: $gold;
	}
	&:after {
		display: block;
		content: "";
		position: absolute;
			bottom: 10px;
			left: 10px;
		width: calc(100% - 20px);
		height: 2px;
		background: $gold;
	}
	&:before {
		display: block;
		content: "";
		position: absolute;
			top: 10px;
			left: 10px;
		width: calc(100% - 20px);
		height: 2px;
		background: $gold;
	}

}
menu {
	position: fixed;
	z-index: 201;
		top: 0;
		left: 0;
	height: 70%;
	width: 100%;
	background: $dark-navy;
	padding-bottom: 2rem;
	transform: translateY(-100%);
	transition: all .4s ease-out;
	.bg-image {
		position: absolute;
		z-index: 2;
			top: 0;
			left: 0;
		height: 100%;
		width: 100%;
		opacity: .2;
	}
	& > .grid-y {
		height: 100%;
		position: relative;
		z-index: 3;
	}
	.link,
	.button {
		position: relative;
		z-index: 3;
		margin-bottom: 20px;
		font-size: 1.3rem;
		transform: translateY(5px);
		opacity: 0;
		transition: all .2s ease-out;
	}
	&.is-active {
		transform: translateY(0%);
		
		.link,
		.button {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}
.book-menu-mobile {
	position: fixed;
	z-index: 202;
		top: 0;
		left: 0;
	height: 100%;
	width: 100%;
	background: $navy;
	padding-bottom: 2rem;
	padding-left: 8vw;
	transform: translateY(-100%);
	transition: all .4s ease-out;
	
	& > .grid-y {
		height: 100%;
		position: relative;
		z-index: 6;
		overflow: auto;
	}
	.link,
	.button {
		position: relative;
		z-index: 3;
		margin-bottom: 20px;
		font-size: 1.3rem;
		transform: translateY(5px);
		opacity: 0;
		transition: all .2s ease-out;
	}
	&.is-active {
		transform: translateY(0%);
		
		.link,
		.button {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}

@media (max-width: 640px) {
	
	header,
	#fixed-header {
		& > .grid-x {
			padding: 10px 5vw;
		}
	}
	
}

// Footer
footer {
	background: $navy;
	padding: 45px 0px;
	
	.logo {
		width: 50px;
		margin-bottom: 15px;
	}
	.logo-container {
		margin-bottom: 30px;
	}
	a {
		font-size: .9rem;
	}
	.nav {
		margin-bottom: 15px;
	}
	.copyright {
		font-size: .9rem;
	}
	
	&.splash {
		padding: 20px 0px;
		.logo-container {
			margin-bottom: 0px;
		}
	}
}

// Main
main {
	&.splash {
		min-height: 90vh;
	}
}

// Banner
.banner {
	
	&.home,
	&.book-page,
	&.splash,
	&.devo-page {
		position: relative;
		z-index: 200;
		padding-top: 100px;
		padding-bottom: 100px;
		min-height: 400px; max-height: 800px;
		
		.number {
			margin-bottom: 30px;
		}
		.icon {
			width: 40px;
		}
		.headline {
			margin-bottom: 30px;
		}
		.outline {
			margin-top: 34px;
		}
		.devotionals {
			h3 {
				font-size: 1.4rem;
			}
		}
		.podcasts {
			h3 {
				font-size: 1.3rem;
			}
		}
	}
	&.book-page {
		max-height: none;
		h1 {
			margin-bottom: 20px;
		}
		.icon {
			width: 40px;
		}
		.outline {
			margin-top: 0px;
			margin-bottom: 10px;
		}
	}
	
	&.devo-page {
		padding-bottom: 3rem;
		
		.bg-image {
			position: absolute;
				top: 0;
				left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			opacity: .05;
		}
		img {
			margin-top: 50px;
		}
	}
	&.donate {
		height: 90vh;
		
		& .grid-container {
			height: 100%;
		}
		.first-grid-x {
			height: 100%;
		}
		.graph {
			padding: 4rem;
			background: #fff;
		}
		#doughnut-chart {
			margin-top: 40px;
			margin-bottom: 50px;
		}
		.donate-form {
			background: #fff;
			padding: 4rem;
		}
		.disclaimer {
			opacity: .5;
			margin-top: 20px;
			p {
				font-size: .8rem;
			}
		}
	}
	&.splash {
		padding-top: 50px;
		height: 90vh;
		min-height: 640px;
		max-height: 900px;
		h1 {
			font-size: 1.7rem;
			font-weight: 500;
			margin-bottom: 25px;
			padding-bottom: 25px;
		}
		p {
			font-size: 1.2rem;
		}
		.responsive-embed {
			margin-bottom: 0px;
		}
		input {
			margin-top: 20px;
			font-size: 1rem !important;
		}
		input[type="text"] {
			font-size: 1rem !important;
			&::placeholder {
				font-size: 1rem !important;
			}
		}
	}
}
@media (max-width: 1024px) {
	.banner {
		&.donate {
			height: auto;
			min-height: 90;
			padding-bottom: 30px;
		}
	}
}
@media (max-width: 640px) {
	.banner {
		&.home {
			max-height: none;
			.number {
				margin-top: 20px;
				margin-bottom: 10px;
			}
		}
		&.book-page {
			.button {
				margin-top: 15px;
			}
			.funnel {
				margin-top: 50px;
			}
		}
		&.donate {
			height: auto;
			min-height: 90
		}
	}
}

.form-container {
	background: $dark-navy;
	input {
		margin-top: 20px;
	}
}

// Section
section, {
	padding: 3.75rem 0px;
	
	&:first-of-type {
		padding-top: 7rem;
	}
	&:last-of-type {
		padding-bottom: 7rem;
	}
	&.pt {
		padding-top: 7rem;
	}
	&.pb {
		padding-bottom: 7rem;
	}
	&.pbt {
		padding: 7rem 0px;
	}
	
	// colors
	&.light-grey {
		background: $light-grey;
	}
}
div {
	
	&.light-grey {
		background: $light-grey;
	}
}

// Email Callout
.email-cta {
	background: $dark-navy;
	h3 {
		margin-bottom: 20px;
	}
	.button {
		display: inline-block;
	}
}

// Side Nav

.side-nav {
	opacity: 1;
	position: fixed;
	z-index: 100;
	left: 2vw;
	top: 50%;
	transform: translateY(-50%);
	white-space: nowrap;
	a {
		display: block;
		padding: 10px 0px;
		&:hover {
			opacity: 1;
			background: none;
		}
		&:hover .page {
			opacity: 1;
			background: #fff;
			.text-span {
				height: 100%;
				max-width: 350px;
				padding: 0px 45px;
				&:before,
				&:after {
					width: 20px;
					margin: 0px 10px;
				}
			}
		}
	}
	.page {
		@extend h5;
		opacity: .2;
		transition: all .3s ease-out;
		.text-span {
			position: relative;
			display: inline-block;
			overflow: hidden;
			max-width: 0px;
			height: 100%;
			transition: all .3s ease-out;
			padding: 0px;
			
			&:after,
			&:before {
				position: absolute;
					top: 50%;
				display: block;
				content: "";
				height: 1px;
				background: $navy;
				width: 0px;
				margin: 0px;
				transform: translateY(-50%);
			}
			&:after {
				right: 0;
			}
			&:before {
				left: 0;
			}
		}
		&.is-active {
			opacity: 1;
		}
	}
}

// Hover Menu
.hover-menu {
	position: relative;
	
	.hover-spacer {
		position: absolute;
	}
	.hovered-menu {
		text-align: left;
		position: absolute;
			top: calc(100%);
			left: 50%;
		padding-top: 35px;
		opacity: 0;
		
		transform: translateX( calc(-50% + .9375rem));
		transition: all .2s ease-out;
		
		.grid-x {
			background: #fff;
			padding: 20px;
		}
		a {
			margin-left: 0px;
			text-transform: none;
		}
	}
	
	&:hover {
		.hovered-menu {
			opacity: 1;
		}
	}
}

// Scroll Animations

.fade-up,
.fade-right {
	transform: translate(0px);
	opacity: 1;
	
	transition: opacity .5s ease-out, transform .8s cubic-bezier(0.23, 1, 0.32, 1);
}
.grow-right {
	transform: scale(1,1);
	transform-origin: left;
	
	transition: transform .8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media (min-width: 1024px) {
	.scroll-animation-trigger {
		.fade-up {
			transform: translateY(20px);
			opacity: 0;
		}
		.fade-right {
			transform: translateX(-20px);
			opacity: 0;
		}
		.grow-right {
			transform: scaleX(0)
		}
	}
}

@for $i from 1 through 10 {
	.transition-delay-#{$i} {
		transition-delay: #{$i / 10}s !important;
	}
}

// Parallax



/*
	
						Pages
	
*/

// -------- Homepage -------- //

// Overview
.home-overview,
.home-devo,
.home-podcasts {
	padding: 15rem 0px !important;
	.icon {
		width: 50px;
	}
	h2 {
		margin-top: 40px;
		margin-bottom: 50px;
	}
	p {
		width: 70%;
	}
}

@media (max-width: 640px) {
	.home-overview,
	.home-devo,
	.home-podcasts {
		padding: 8rem 0px !important;
	}
}
.gold-foil-bg {
	position: relative;
	z-index: 1;
	
	.foil-bg {
		
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
			left: -10px;
			bottom: -10px;
		z-index: -1;
	}
	.foil-image {
		min-width: 100%;
	}
	&.right-aligned {
		.foil-bg {
			right: -10px;
			left: auto;
		}
	}
}
@media (max-width: 640px) {
	
	.gold-foil-bg {
		margin-bottom: 50px;
	}
	
}

// Devo
.home-devo {
	margin: 0 auto;
	p {
		width: 85%;
	}
}

// Podcasts
.home-podcasts {
	margin: 0 auto;
}

// Email CTA
.email-cta {
	
	.button {
		max-height: 100%;
	}
	
}
@media (max-width: 640px) {
	.email-cta {
		.button {
			margin-top: 2rem;
		}
	}
}

// -------- Explore -------- //

// Banner

.search-banner {
	h1 {
		margin-bottom: 40px;
	}
	h3 {
		font-weight: 700;
		max-height: 100%;
		background: #f2f2f2;
		border: 1px solid $navy;
		font-size: 1.2rem;
		padding: 6.5px 20px;
	}
	select {
		cursor: pointer;
		background: #f2f2f2;
		border: 1px solid $navy;
		border-left: .5px solid $navy;
		border-right: .5px solid $navy;
		transition: all .3s ease-out;
		
		&:hover {
			border: 1px solid $navy;
			background: #fff;
		}
	}
	input[type="submit"] {
		@extend .button;
		@extend p;
		border-radius: 0px;
		outline: none;
		border: none;
		padding: 10px 40px;
		transition: all .3s ease-out;
		cursor: pointer;
		border-left: 1px solid $navy;
		&:hover {
			opacity: .8;
		}
		
	}
}
@media (max-width: 640px) {
	
	.search-banner {
		select {
			margin-top: 10px;
		}
		input[type="submit"] {
			
			margin-top: 20px;
			
		}
	}
	
}

// Sections

.books,
.introductions,
.poem,
.teachings,
.devotionals,
.podcasts {
	.icon {
		width: 50px;
	}
	.header {
		margin-bottom: 40px;
	}
	.sub-nav {
		margin-bottom: 20px;
	}
	.devotional-container {
		.devotional {
			position: relative;
			padding: 30px;
			border: 1px solid transparent;
			transition: all .3s ease-out;
			&:hover {
				border: 1px solid $navy;
			}
		}
	}
	.poem,
	.teaching,
	.devotional-container {
		position: relative;
		padding: 30px;
		margin-bottom: 60px;
		background: #F2F2F2;
		
		p {
			color: #999;
			margin-top: 10px;
			margin-bottom: 20px;
		}
		
		.poem-icon,
		.teaching-icon,
		.devotional-icon {
			position: absolute;
				top: 0;
				left: 50%;
			height: 60px;
			transform: translate(-50%, -50%);
		}
		
		.image {
			position: relative;
			height: 130px;
		}
	}
	.poem,
	.devotional-container {
		background: #fff;
	}
	.devotional-container {
		padding: 0px;
	}
}
.book-list {
	.cell {
		padding: 10px 30px;
		background: #F2F2F2;
		
		width: calc(20% - .5em);
		margin: .25em;
	}
}
.devotionals {
	padding-bottom: 7rem;
	
	h3 {
		font-size: 1.6rem;
		text-transform: none;
		margin-bottom: 15px;
	}
	h5 {
		margin-bottom: 25px;
		margin-top: 30px;
	}
	p {
		margin-bottom: 25px;
		max-width: 48em;
		line-height: 1.7em;
		letter-spacing: 0em;
		color: #222;
	}
}
.podcasts {
	margin-bottom: 7rem;
	h2 {
		margin-bottom: 25px;
	}
	img {
		margin-bottom: 20px;
	}
	p {
		margin-bottom: 15px;
	}
}

#sub-nav {
		top: 0;
		left: 0;
	width: 100%;
	background: $dark-navy;
	padding: 20px 0px;
	transition: all .2s ease-out;
	transition-delay: 0s;
	
	&.active {
		position: fixed;
		top: 85px;
		z-index: 200;
	}
	a {
		color: #fff;
	}
}
.no-margin {
	margin: 0px !important;
}

@media (max-width: 1024px) {
	
	.books {
		h3 {
			margin-bottom: 5px;
		}
	}
	.book-list {
		.cell {
			width: calc(33% - .5em);
			margin: .25em;
		}
	}
	
}

@media (max-width: 640px) {
	.book-list {
		.cell {
			width: calc(100% - .5em);
			margin: .25em;
		}
	}
}

// -------- Book -------- //

.book-banner {
	h1 {
		margin-bottom: 50px;
	}
}

.book-overview {
	h2 {
		margin-bottom: 50px;
	}
	p {
		max-width: 48em;
	}
}

.book-reading-plan {
	h2 {
		margin-bottom: 50px;
	}
	.reading-item {
		background: $light-grey;
		padding: 10px;
		text-align: center;
		border: 1px solid $light-grey;
		
		transition: all .2s ease-out;
		
		&:hover {
			border: 1px solid $navy;
		}
	}
}

.book-devotional {
	position: fixed;
		top: 0;
		left: 50%;
	z-index: 400;
	width: 90vw;
	height: 100vh;
	background: #fff;
	transform: translateX(-50%);
}
.devotional-overlay {
	position: fixed;
		top: 0;
		left: 0;
		z-index: 300;
	width: 100vw;
	height: 100vh;
	background: $navy;
	opacity: .5;
}
.devotional-podcasts {
	margin-top: 40px;
	h2 {
		margin-bottom: 20px;
	}
}
.devotional-container {
	
	&.grey {
		background: #f2f2f2;
	}
	.grid-y {
		height: 100%;
	}
	.learn-more-button {
		margin-top: 20px;
	}
	.listen-button {
		margin-top: 20px;
	}
	.podcasts {
		padding: 20px 0px;
		margin-top: 70px;
		h3 {
			margin-bottom: 30px;
		}
	}
	.email-journeys {
		padding: 40px;
		margin-top: 70px;
		
		.grid-x {
			height: 100%;
		}
		h5 {
			font-size: 1.2rem;
		}
		input {
			margin-top: 25px;
			margin-bottom: 25px;
		}
	}

}

.book-blogs {
	h2 {
		font-size: 1.6rem;
		padding: 0px 50px;
	}
	
	.header {
		margin-bottom: 70px;
	}
	
	.blog-post {
		border: 1px solid #ccc;
		.grid-y {
			height: 100%;
		}
		.copy-container {
			position: relative;
			padding: 20px;
			h3 {
				font-size: 1.5rem;
				letter-spacing: .1em;
				margin-bottom: 10px;
			}
			h5 {
				margin-bottom: 30px;
			}
		}
		.image-container {
			height: 150px;
			position: relative;
			img {
				position: absolute;
				top: 0;
				left: 50%;
				height: 50px;
				
				transform: translate(-50%, -50%);
			}
		}
	}
	.see-all {
		margin-top: 60px;
	}
}

.book-deeper {
	h2 {
		margin-bottom: 50px;
	}
	.links {
		h3 {
			margin-bottom: 20px;
		}
		.button {
			background: #fff;
			width: 100%; max-width: 250px;
			text-align: center;
			color: $navy;
			margin-top: 10px;
			border: 1px solid transparent;
			&:hover {
				border: 1px solid #ddd;
			}
		}
	}
	.readings {
		h5 {
			margin-bottom: 20px;
		}
		img {
			max-height: 200px;
			margin-bottom: 20px;
		}
		.grid-y {
			height: 100%;
		}
		p {
			color: #808080;
		}
	}
}
@media (max-width: 640px) {
	.book-deeper {
		.links {
			margin-bottom: 45px;
			text-align: center;
		}
		.readings {
			.book {
				margin-top: 50px;
			}
			h5 {
				max-width: 20em;
				margin: 0 auto;
				margin-bottom: 20px;
			}
		}
	}
}

.introductions,
.podcasts {
	.introduction-video {
		width: 14vw;
		height: 14vw;
	}
	.see-more {
		margin-top: 30px;
	}
}

.callout {
	h1 {
		margin-top: 25px;
		margin-bottom: 20px;
	}
}
@media (max-width: 640px) {
	
	.introductions,
	.podcasts {
		.introduction-video {
			width: 80vw;
			height: 80vw;
		}
		h3 {
			margin-bottom: 30px;
			margin-top: 10px;
		}
	}
	
}
.screen-overlay {
	position: fixed;
		top: 0;
		left: 0;
	background: $navy;
	height: 100%;
	width: 100%;
	z-index: 200;
	opacity: 0;
	transform: translateY(-100%);
	transition: opacity .3s ease-out;
	
	&.is-active {
		opacity: .3;
		transform: translateY(0%);
	}
}
.podcast-overlay {
	position: fixed;
		top: 50%;
		left: 50%;
	background: #fff;
	z-index: 201;
	padding: 50px;
	opacity: 0;
	transform: translate(-300%, -50%);
	transition: opacity;
	.cross {
		cursor: pointer;
		position: absolute;
			top: 50px;
			right: 50px;
		height: 20px;
	}
	h3 {
		margin-bottom: 50px;
	}
	iframe {
		margin-top: 40px;
	}
	&.is-active {
		transform: translate(-50%, -50%);
		opacity: 1;
	}
}
.resources {
	.square {
		margin-top: 40px;
		background: #fff;
		margin-bottom: 30px;
		border: 1px solid transparent;
		&.navy {
			background: $navy;
		}
		h3, h5 {
			padding: 20px;
		}
		h5.lowercase {
			padding-top: 0px;
		}
		&:hover {
			border: 1px solid $navy;
			opacity: 1;
		}
		&.padded {
			padding: 20px;
		}
	}
	.bg-image {
		height: 100px;
		width: 100%;
	}
	.grid-y {
		height: 100%;
	}
}

// ---------- Entries -------- //

.headline {
	.books {
		line-height: .6em;
		vertical-align: bottom;
	}
	.topics {
		line-height: .6em;
		vertical-align: bottom;
	}
	h1 {
		margin-bottom: 10px;
		margin-top: 20px;
	}
}
.content {
	
	.subheader {
		margin-top: 30px;
		margin-bottom: 10px;
	}
	
}

/*
	
	
	About
	
	
*/
.about-banner {
	position: relative;
	z-index: 200;
	min-height: 85vh;
	background: #fff;
	
	.bg-image {
		opacity: .6;
		position: absolute;
		z-index: 101;
			top: 0;
			left: 0;
		width: 100%;
		height: 100%;
	}
}
.about-content {
	position: absolute;
	z-index: 102;
		top: 50%;
		left: 0;
	background: #fff;
	padding: 60px;
	width: 85%;
	transform: translateY(-50%);
	
	h5 {
		display: inline-block;
		position: relative;
		padding-bottom: 30px;
		margin-bottom: 30px;
		min-width: 70%;
		
		&:after {
			display: block;
			content: "";
			position: absolute;
				bottom: 0;
				left: 0;
			width: 100%;
			height: 2px;
			background: $gold;
		}
	}
}
@media (max-width: 640px) {

	.about-content {
		width: 92%;
		padding: 40px 20px;
		
		h1 {
			font-size: 2rem;
		}
	}
	
}
.what-we-make {
	h1 {
		margin-bottom: 90px;
	}
	.icon {
		height: 50px;
	}
	h3 {
		display: inline-block;
		margin: 0px;
		line-height: 1em;
		font-weight: 400;
		margin-left: 25px;
	}
	p {
		margin-top: 40px;
	}
	.copy {
		padding-right: 30px;
		
		&.right {
			padding-left: 30px;
			padding-right: 0px;
		}
	}
	.item {
		padding-bottom: 7rem;
		&:last-of-type {
			padding-bottom: 0px;
		}
	}
}
@media (max-width: 640px) {
	
	.what-we-make {
		.image {
			margin-left: 0px;
			margin-top: 20px;
			margin-bottom: 20px;
			display: block;
		}
		.copy,
		.copy.right {
			padding: 0px;
			padding-left: 0px;
			padding-right: 0px;
		}
	}
	
}
.what-is-sg {
	
	.header-row {
		margin-bottom: 70px;
	}
	h5 {
		line-height: 1.5em;
		max-width: 24em;
		position: relative;
		
		&:after {
			display: block;
			content: "";
			position: absolute;
			top: -15px;
			left: -30px;
			width: 2px;
			height: calc(100% + 30px);
			background: $gold;
		}
	}
	.content {
		margin-top: 100px;
		p {
			margin-bottom: 15px;
			line-height: 1.7em;
		}
	}
	p.callout {
		position: relative;
		font-weight: 700;
		margin-top: 30px;
		margin-bottom: 30px;
		font-size: 1.4rem;
		line-height: 1.2em;
		.quote-begin {
			position: absolute;
				left: -20px;
				top: -10px;
			color: $gold;
			font-size: 24px;
		}
		.quote-end {
			color: $gold;
			font-size: 24px;
		}
	}
	
}
@media (max-width: 640px) {
	.what-is-sg {
		.header-row {
			margin-bottom: 30px;
		}
		h5 {
			padding-top: 30px;
			margin-top: 30px;
			margin-bottom: 30px;
			&:after {
				top: 0;
				left: -5px;
				height: 2px;
				width: calc(100% + 10px);
			}
		}
		.content {
			margin-top: 30px;
			p {
				margin-bottom: 20px;
				&.callout {
					margin-top: 30px;
					margin-bottom: 30px;
				}
			}
		}
	}
}
.team {
	
	h1 {
		margin-bottom: 70px;
	}
	.team-member {
		padding: 40px;
		background: #f2f2f2;
		h5 {
			margin-bottom: 40px;
		}
		p {
			line-height: 1.6em;
		}
	}
	
}

// Donate
#doughnut-chart {
	max-width: 300px;
	margin: 0 auto;
}
@media (max-width: 640px) {
	#doughnut-chart {
		margin-bottom: 40px;
	}
}
.donate-form {
	input {
		border: none;
		border-radius: 0px;
		border-bottom: 1px solid $navy;
		box-shadow: none;
		outline: none;
		padding-left: 0px;
		
		&::placeholder {
			@extend p;
			color: #bbb;
		}
		&:hover,
		&:focus {
			box-shadow: none;
			outline: none;
			border: none;
			border-bottom: 1px solid $navy;
			border-radius: 0px;
		}
	}
	input[type="submit"] {
		margin-top: 20px;
		padding: 10px 30px;
	}
	h5 {
		margin-top: 20px;
		margin-bottom: 10px;
		&.lowercase {
			margin-top: 0px;
		}
	}
	p {
		margin-top: 20px;
	}
}